import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withRouter } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import AccountTab from '../../Tabs/Settings/Account';
import VideosTab from '../../Tabs/Settings/Videos';
import settings from '../../settings';
// import AppearanceTab from '../Tabs/Settings/Appearance';

const styles = (theme) => ({
  container: {
    maxWidth: 600,
    margin: '0 auto',
    marginTop: theme.spacing(4),
  },
  tabs: {
    marginBottom: theme.spacing(1)
  },
  closeButton: {
    marginRight: theme.spacing(2),
  },
});


const tabForPath = {
  account: 0,
  videos: 1,
}

const getTabForPath = (pathname = "") => {
  const lastSegment = pathname.split('/').pop()
  return tabForPath[lastSegment] || 0;
}

const getPathForTab = tabIdx => {
  return Object.entries(tabForPath).find(e => e[1] === tabIdx)[0];
}

class Settings extends Component {
  constructor(props) {
    super(props);

    const { location } = props;
    this.state = {
      selectedTab: getTabForPath(location.pathname),
    };
  }

  componentDidMount() {
    this.updatePath();
  }

  changeTab = (event, value) => {
    this.setState({
      selectedTab: value
    }, this.updatePath)
  };

  updatePath = () => {
    const { selectedTab } = this.state;
    const segment = getPathForTab(selectedTab);
    this.props.history.push(`${settings.routes.SETTINGS}/${segment}`);
  }

  render() {
    // Styling
    const { classes } = this.props;

    // Properties
    const {
      user,
      isPerformingAuthAction,
      onUpgradeClick,
      onExtendClick,
    } = this.props;

    // Events
    const {
      onCancelSubscriptionClick,
      getSubscription,
    } = this.props;

    const { selectedTab } = this.state;

    return (
      <div className={classes.container}>
        <Tabs
          className={classes.tabs}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.changeTab}
          value={selectedTab}
          variant="fullWidth"
          // NOTE: this TabIndicatorProps is for the case when we only have one tab
          // TabIndicatorProps={{ style: { width: '100%' }}}
        >
          <Tab label="Account" />
          <Tab label="Videos" />
        </Tabs>

        {selectedTab === 0 &&
        <AccountTab
          user={user}
          isPerformingAuthAction={isPerformingAuthAction}
          onCancelSubscriptionClick={onCancelSubscriptionClick}
          getSubscription={getSubscription}
          onUpgradeClick={onUpgradeClick}
          onExtendClick={onExtendClick}
          onEnablePasswordLogin={this.props.onEnablePasswordLogin}
        />
        }
        {selectedTab === 1 &&
        <VideosTab
          user={user}
          getSubscription={getSubscription}
          onUpgradeClick={onUpgradeClick}
        />
        }
        {/*
        {selectedTab === 2 &&
        <AppearanceTab
            user={user}
            isPerformingAuthAction={isPerformingAuthAction}
            layoutDirection={layoutDirection}
            onLayoutDirectionChange={onLayoutDirectionChange}
        />
        }
        */}
    </div>
    );
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired,

  user: PropTypes.object.isRequired,
  isPerformingAuthAction: PropTypes.bool.isRequired,

  onUpgradeClick: PropTypes.func.isRequired,
  onExtendClick: PropTypes.func.isRequired,
  onCancelSubscriptionClick: PropTypes.func.isRequired,
  getSubscription: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(Settings));
